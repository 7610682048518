
@font-face {
    font-family: Bariol;
    src: url('/fonts/Bariol_Regular.otf');
}
@font-face {
    font-family: Montserrat;
    src: url('/fonts/MontserratAlternates-Black.ttf');
}
@font-face {
    font-family: Varela;
    src: url('/fonts/Varela-Regular.ttf');
}


$headings-font-family: Montserrat;


$primary: #b4b4b4;
$secondary: #6e67ff;
$dark: #1D1F32;
$light: #F5F5F4;
$info: #ff5777;
$warning: #e4c900;
$success: #4F9858;
$danger: #F44336;

@import "~bootstrap/scss/bootstrap.scss";

label{
    font-family: Montserrat;
}