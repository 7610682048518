.pointer{
    cursor: pointer
}
.w-collapsed{
    width: 200px;
    position: fixed;
    top: -250px;
    right: 0px;
    transition: top .3s ease 0s;
    z-index: 1;
}
.w-show{
    width: 200px;
    position: fixed;
    top: 60px;
    right: 0px;
    transition: all .3s ease 0s;
    z-index: 1;
}
.nav__dropdown--list{
    list-style: none;
}
.slider_img{
    height: 600px;
    width: 100vw;
}
.rbtc-baul{
    
    
    background-color: red;
    color: yellow;
    padding: 5px;
    border: 2px solid;
    box-shadow: -2px 22px 26px -18px rgb(0 0 0 / 75%);
    font-weight: bold;
    -webkit-box-shadow: -1px 20px 31px -12px rgba(112,108,112,1);
    -moz-box-shadow: -1px 20px 31px -12px rgba(112,108,112,1);
    box-shadow: -1px 20px 31px -12px rgba(112,108,112,1);

}
.baul-text-header{
    font-size:12px;
    color: #fff;
}
.baul-text-header-desk{
    font-size: 14px;
    color: #fff;
}
.baul-mobile{

    display: flex;
    flex-direction: column;
    align-items: center;
}